import "./App.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Table, Model, Modal } from "antd";
import ReactJson from "react-json-view";
import "antd/dist/reset.css";

function App() {
  const [logs, setLogs] = useState([]);
  const [logDetail, setLogDetail] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // let baseUrl = "http://localhost:3000/"
  let connectionOpen = false;

  let baseUrl = "https://api.erp.refogen.com/";

  useEffect(() => {
    fetchLogs();
    initiateSSE();
  }, []);

  const showModal = async (id) => {
    await axios.get(baseUrl + "logs/" + id).then((res) => {
      console.log(res.data);
      if (res.data.success) {
        setLogDetail(res.data.data);
      }
    });
    setIsModalOpen(true);
  };
  const initiateSSE = async () => {
    const eventSource = new EventSource(baseUrl + "sse");

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      data.key = data._id;
      data.url = { url: data.url, id: data._id };
      console.log("Message received:", data);
      setLogs((prevData) => [data, ...prevData]);
    };

    eventSource.onopen = (event) => {
      if (event.type === "open" && !connectionOpen) {
        connectionOpen = true;
      }
    };
  };

  console.log("Log", logs);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text) => (
        <a className="text-primary" onClick={() => showModal(text.id)}>
          {text.url}
        </a>
      ),
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
      render: (text) => (
        <em className={getMethodColor(text)}>
          <strong>{text}</strong>
        </em>
      ),
    },
    {
      title: "Status Code",
      dataIndex: "statusCode",
      key: "statusCode",
      render: (text) => (
        <em className={getStatusCodeColor(text)}>
          <strong>{text}</strong>
        </em>
      ),
    },
    {
      title: "Response Time",
      dataIndex: "responseTime",
      key: "responseTime",
      render: (text) => (
        <em className={getResponseTimeColor(text)}>
          <strong>{`${text} ms`}</strong>
        </em>
      ),
    },
    {
      title: "DateTime",
      dataIndex: "dateTime",
      key: "dateTime",
      render: (text) => (
        <em>
          <strong>{formatDate(text)}</strong>
        </em>
      ),
    },
  ];

  const getStatusCodeColor = (code) => {
    let statusCode = parseInt(code);
    if (statusCode < 300) return "text-success";
    if (statusCode < 400) return "text-warning";
    if (statusCode < 500) return "text-warning-emphasis";
    return "text-danger";
  };

  const getResponseTimeColor = (time) => {
    let resTime = parseInt(time);
    if (resTime < 20) return "text-success";
    if (resTime < 50) return "text-warning";
    if (resTime < 100) return "text-warning-emphasis";
    return "text-danger";
  };
  const getMethodColor = (method) => {
    switch (method) {
      case "GET":
        return "text-success";
      case "POST":
        return "text-warning";
      case "PATCH":
        return "text-warning-emphasis";
      case "DELETE":
        return "text-danger";
      default:
        return "text-danger";
    }
  };

  const formatDate = (dateVal) => {
    var newDate = new Date(dateVal);

    var sMonth = padValue(newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    var sSeconds = padValue(newDate.getSeconds());
    var sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
    } else if (iHourCheck === 0) {
      sHour = "12";
    }

    sHour = padValue(sHour);

    return (
      sMonth +
      "-" +
      sDay +
      "-" +
      sYear +
      " " +
      sHour +
      ":" +
      sMinute +
      ":" +
      sSeconds +
      " " +
      sAMPM
    );
  };

  const padValue = (value) => {
    return value < 10 ? "0" + value : value;
  };
  const fetchLogs = async () => {
    await axios
      .get(baseUrl + "logs?startIndex=0&noOfItems=1000")
      .then((res) => {
        // console.log(res.data)
        if (res.data.success) {
          res.data.data.leads.forEach((item) => {
            item.key = item._id;
            item.url = { url: item.url, id: item._id };
          });
          console.log(res.data.data.leads);
          setLogs(res.data.data.leads);
        }
      });
  };
  return (
    <div className="container-fluid">
      <h1>Logs</h1>
      <Table
        pagination={{ pageSize: 100 }}
        columns={columns}
        dataSource={logs}
      />
      <Modal
        title="View Log"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1500}
      >
        <ReactJson src={logDetail} />
      </Modal>
    </div>
  );
}

export default App;
